import React from "react"
import { Layout } from "../../layout"
import { SecondaryColor } from "../../ux/colors"
import { Eyebrow } from "../../components/Eyebrow"
import ClinkArrow from "../../images/svgs/svg1.svg"
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import NeededKnowledgePic from "../../images/blogs-pictures/neededknowledge.webp";

const SERVICE_ID = "service_mb9j2or";
const TEMPLATE_ID = "template_jqwqwvl";
const PUBLIC_KEY = "dwn2MSnCF3SN4auAM";

export const NeededKnowledge = (): JSX.Element => {

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then((result) => {
        console.log(result.text);
        Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
        })
        }, (error) => {
        console.log(error.text);
        Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
        })
        });
    e.target.reset()
  };

  return(
    <Layout>
      <div className="container mx-auto flex justify-center">
        <div className="flex flex-col items-top gap-6 py-20 w-4/5">
          <h1 className="font-display md:text-display-lg sm:text-display-md text-display-sm text-primary-600 mb-8">
            Bar Service for Corporate Events: What You Need to Know
          </h1>
          <p className="font-display ">
            Are you facing the challenge of organizing your upcoming corporate event? Whether it&#39;s a
            conference, a meeting, or a lively holiday gathering, making the right choice for catering and bar
            services is essential. Whether you&#39;re in need of a full bar setup or just beer and wine, here&#39;s
            what you should keep in mind when selecting the ideal bar service for corporate events.
          </p>
          <div className="flex justify-center">
            <img src={NeededKnowledgePic} className="lg:w-2/3 w-full my-8 rounded-lg shadow-md" alt="Professional bar setup for your corporate event"/>
          </div>
          <p className="font-display md:text-display-xs text-display-xs">
            Selecting a Corporate Catering Company
          </p>
          <p className="font-display ">
            It&#39;s crucial to find a bar service provider with experience in handling corporate events. Bar
            service in a professional environment is quite different from that at private functions like
            weddings. You want a company that places a premium on quality. A licensed and experienced
            bartender will serve your colleagues with the professionalism expected in a corporate setting.
          </p>
          <p className="font-display mb-12">
            Corporate events require careful attention to bar service. Mixing alcohol and employees can
            potentially lead to problems for companies. Trusted service providers are licensed, insured, and
            employ bartenders who are specially trained and certified to ensure guest safety and
            responsible alcohol consumption.
          </p>
          <p className="font-display md:text-display-xs text-display-xs">
            Choosing the Right Bar Service For Your Corporate Event
          </p>
          <p className="font-display ">
            Before making a decision on a local catering company for your bar service, it&#39;s essential to
            understand the various types of bar services available for corporate events. Here are some key
            factors to consider when selecting a catering company for your corporate function:
          </p>
          <ul className="flex flex-col gap-4 mb-12">
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Full Bar Service, Beer &amp; Wine, and More:</span> Full bar service provides a wide selection of
                  spirits, beer, and wine, including options for premium liquor, fine wines, and craft beers.
                  Alternatively, beer and wine service may be more appropriate for business functions,
                  while coffee and tea might be preferable for office meetings during work hours.
                </p>
            </li>
            <li className="flex">
                <img className="h-4 w-6 me-2 mt-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                <p>
                  <span className="font-semibold">Cash Bar or Hosted Bar:</span> Choose between a hosted bar, where the host covers all
                  alcohol expenses, or a cash bar, where guests pay for their own drinks. The decision
                  depends on the purpose of the event; for impressing clients, a hosted bar may be the
                  better choice, while a cash bar might be suitable for a midweek networking event.
                </p>
            </li>
          </ul>
          <p className="font-display md:text-display-xs text-display-xs">
            Corporate Event Size and Budget
          </p>
          <p className="font-display ">
            Consider the expected number of guests and the duration of your event to determine your
            budget and bartender requirements. Your chosen bar service provider will help finalize these
            details, ensuring you have the right amount of alcohol, mixers, soft drinks, and ice.
          </p>
          <p className="font-display md:text-display-xs text-display-xs">
            Clink Bar Services for Your Corporate Event
          </p>
          <p className="font-display mb-12">
            Selecting a bar service for your corporate event doesn&#39;t have to be overwhelming. Regardless
            of the occasion, a seasoned company can handle every aspect, allowing you to relax. Clink Bar
            Services serves the Tampa Bay area, offering experienced staff to assist in planning an
            unforgettable event. Fill out our brief questionnaire to receive a complimentary consultation.
          </p>
          <Eyebrow label="CONTACT US"/>
          <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
            Get In Touch With <span className="italic">Our Team</span>
          </h3>
          <div className="container flex flex-col items-center w-full">
            <form onSubmit={handleOnSubmit} className="flex flex-col items-center mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:px-6">
              <input type="hidden" name="form-name" value="clink-contact-us-form" />
              <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 gap-2">
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="name">
                      Name
                      <input type="text" id="name" name="name" className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" placeholder="Name" required />
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="email">
                      Email
                      <input type="email" className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" id="email" name="email" placeholder="Enter your email address" required/>
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="phone">
                      Phone
                      <input type="tel" className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="phone" id="phone" placeholder="Enter your phone number" required />
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="date">
                      Date
                      <input type='date' className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="date" id="date" />
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="location">
                      Location
                      <input className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="location" id="location" placeholder="Enter event's location"/>
                    </label>
                </div>
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="attendance">
                      Estimated Attendance
                      <input className="p-2 w-full border rounded-md focus:ring-blue-500 focus:border-blue-500" name="attendance" id="attendance" placeholder="Enter the estimated attendance" required />
                    </label>
                </div>
              </div>
              <button type="submit" className="mt-6 inline-block w-3/6 rounded px-6 py-2.5 font-medium uppercase leading-normal hover:shadow-md hover:bg-teal-500
                button flex gap-1 items-center justify-center bg-primary-600 text-white font-semibold px-6 py-4 text-body-sm">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
)}