import React from "react";

import { HeroSection, SeoSection, ServicesSection, AboutSection, ContactUsSection, OurProjectsSection } from "./sections";
import { BlogsSection } from "./sections/BlogsSection";
import {Layout} from "../layout";
//import Awards from "../components/awards";
//import Works from "../components/works";

const IndexPage = () => (
  <Layout>
    <SeoSection />
    <HeroSection />
    <AboutSection />
    <ServicesSection />
    <OurProjectsSection />
    <BlogsSection />
    <ContactUsSection />
  </Layout>
);

export default IndexPage;
