import { ContactButton } from "../../components";
import { Eyebrow } from "../../components/Eyebrow";
import { ProjectItem } from "../../components/ProjectItem";
import weddingImage from '../../images/bartender.jpeg';
import corporateImage from '../../images/catering.jpg';
import partiesImage from '../../images/events.jpg';

type ProjectData = {
  image: string;
  title: string;
  description: string;
}

const projectsData: ProjectData[] = [
  {image: weddingImage, title: "Weddings", description: "Transform your dream wedding into an unforgettable reality with our unparalleled service and creativity."},
  {image: corporateImage, title: "Corporate events", description: "Elevate your corporate events with our exceptional planning services, where creativity meets professionalism. From conferences to company celebrations, we guarantee to impress your team and guests to reflect your brand's excellence."},
  {image: partiesImage, title: "Parties", description: "Whether it's a milestone birthday, an anniversary, or just because, we bring a touch of magic and unparalleled attention to detail, ensuring a memorable experience that's as unique as you are."},
]

export const OurProjectsSection = () => {
  return (
    <div id="events">
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12">
          <div className="grid xl:grid-cols-12 grid-cols-1 xl:gap-8 gap-10 items-center">
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10">
              <div className="flex flex-col gap-6">
                <Eyebrow label="SOME OF OUR EVENTS" />
                <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
                  Some of <span className="italic">our crafts</span> made with
                  love
                </h3>
              </div>
              {projectsData.slice(0, 1).map((project, id) => (
                <ProjectItem
                  key={id}
                  image={project.image}
                  title={project.title}
                  description={project.description}
                />
              ))}
              <div className="xl:flex hidden items-start">
                <ContactButton size="lg" />
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-8 flex flex-col xl:gap-24 md:gap-20 gap-10 xl:px-14">
              {projectsData.slice(1, 3).map((project, id) => (
                <ProjectItem
                  key={id}
                  image={project.image}
                  title={project.title}
                  description={project.description}
                />
              ))}
            </div>
          </div>
          <div className="xl:hidden flex items-start">
            <ContactButton size="lg" />
          </div>
        </div>
      </div>
    </div>
  );
};
