import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home';
import { WeddingsBlog } from "./pages/blogs/WeddingsBlog"
import { NeededKnowledge } from "./pages/blogs/NeededKnowledge"
import { UnforgettableParty } from "./pages/blogs/UnforgettableParty"

// const TRACKING_ID = "UA-XXXXXXX-X";
// ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Home />} />
        <Route path="/blogs/weddings" element={<WeddingsBlog />} />
        <Route path="/blogs/neededKnowledge" element={<NeededKnowledge />} />
        <Route path="/blogs/unforgettableParty" element={<UnforgettableParty />} />
        <Route path="*" element={<Home />} /> {/* This will catch anything that doesn't match the above routes */}
      </Routes>
    </BrowserRouter>
  );
}


export default App;
