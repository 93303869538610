import { OriginalButton } from "./Button"

type ContactButtonProps = {
  size?: string;
}

export const ContactButton = ({size}:ContactButtonProps):JSX.Element => {
  return (
    <OriginalButton label="Contact" link="/#contact" size={size}/>
  )
}

type BlogButtonProps = {
  size: string;
  href: string;
}

export const BlogButton = ({size, href}: BlogButtonProps): JSX.Element => {
  return(
    <a
        className={`button flex w-24 mt-4 rounded gap-1 items-center justify-center bg-primary-600 text-white font-semibold ${
          size === "lg" ? "px-2 py-2 text-body-sm" : "px-2 py-2 text-body-xs"
        }`}
        href={href}
    >
      To the blog
    </a>
  )
}
