import {Eyebrow} from "../../components/Eyebrow";
import aboutCreative from '../../images/about-creative.png';
import ClinkArrow from "../../images/svgs/svg1.svg";
import DishIcon from "../../images/svgs/svg4.svg";
import MusicIcon from "../../images/svgs/svg3.svg";
import GlassIcon from "../../images/svgs/svg2.svg";
import { SecondaryColor } from "../../ux/colors";

export const AboutSection = () => {
  return (
    <div id="about">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="ABOUT US" />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
              Why Clink? <span className="italic">Simple!</span>{" "}
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            At Clink Social Spirits, we understand the importance of catering to your event's unique requirements. As a mobile bartending service, we'll take care of everything!
            </p>
            <ul>
              <li className="flex items-center">
                <img className="h-4 w-6 me-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                Full Bar Service and Staffing
              </li>
              <li className="flex items-center">
                <img className="h-4 w-6 me-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                Food Catering
              </li>
              <li className="flex items-center">
                <img className="h-4 w-6 me-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                Music and Entertainment Bookings
              </li>
              <li className="flex items-center">
                <img className="h-4 w-6 me-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                On-site Event Management
              </li>
              <li className="flex items-center">
                <img className="h-4 w-6 me-2" style={{fill:SecondaryColor}} src={ClinkArrow} alt="Logo" />
                And much more!
              </li>
            </ul>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            With a focus on flexibility and attention to detail, we ensure that every aspect of your event is expertly executed, leaving you free to enjoy a seamless and unforgettable occasion
            </p>
            <div className="flex flex-row w-full">
              <div className="flex flex-col items-center justify-center basis-1/3">
                <img className="h-20" src={MusicIcon} alt="description"/>
                <p className="md:text-body-lg text-body-md font-light text-neutral-700">
                  DJ Options
                </p>
              </div>
              <div className="flex flex-col items-center justify-center basis-1/3">
                <img className="h-20" src={DishIcon} alt="description"/>
                <p className="md:text-body-lg text-body-md font-light text-neutral-700">
                  Catering Packages
                </p>
              </div>
              <div className="flex flex-col items-center justify-center basis-1/3">
                <img className="h-20" src={GlassIcon} alt="description"/>
                <p className="md:text-body-lg text-body-md font-light text-neutral-700">
                  Bar Services
                </p>
              </div>
            </div>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
          <img src={aboutCreative} alt="Description" width="592" />
            {/* <img
              src={AwardBadge}
              alt="Award Badge"
              className="absolute left-[42%] -top-14"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
