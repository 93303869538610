import {Eyebrow} from "../../components/Eyebrow";
import {ServiceItem} from "../../components/ServiceItem";
import DishIcon from "../../images/svgs/svg4.svg";
import MusicIcon from "../../images/svgs/svg3.svg";
import GlassIcon from "../../images/svgs/svg2.svg";

export const ServicesSection = () => {
  return (
    <div id="services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="OUR SERVICES" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                We provide the <span className="italic">best solutions</span>{" "}
                for your dream event
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={GlassIcon}
              title="Bar Services"
              description="From expertly crafted cocktails or refreshing mocktails, our bar services cater to every palate. Share your preferences with us, and we'll curate a custom bar package tailored to your unique tastes and preferences."
            />
            <ServiceItem
              icon={DishIcon}
              title="Catering Packages"
              description="Explore our full range of food options. Whether you're seeking a sampling of flavors or a comprehensive feast, our diverse menu options ensure that every taste is satisfied."
            />
            <ServiceItem
              icon={MusicIcon}
              title="Music and Ambiance"
              description="We work with a roster of experienced DJs, each offering a unique selection of music genres to set the desired mood for your event."
            />
          </div>
        </div>
      </div>
    </div>
  );
};