import {Eyebrow} from "../../components/Eyebrow";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const SERVICE_ID = "service_mb9j2or";
const TEMPLATE_ID = "template_jqwqwvl";
const PUBLIC_KEY = "dwn2MSnCF3SN4auAM";

export const ContactUsSection = () => {
    const handleOnSubmit = (e: any) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
            .then((result) => {
            console.log(result.text);
            Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully'
            })
            }, (error) => {
            console.log(error.text);
            Swal.fire({
                icon: 'error',
                title: 'Ooops, something went wrong',
                text: error.text,
            })
            });
        e.target.reset()
    };

  return (
    <div id="contact">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-16 py-4 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="CONTACT US" />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
            Let’s begin crafting your event
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            Plan your perfect event with us, we are here at your service.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center">
            <form onSubmit={handleOnSubmit} className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
                <input type="hidden" name="form-name" value="clink-contact-us-form" />
                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="name">
                            Name
                      <input type="text" id="name" name="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2" placeholder="Name" required />
                    </label>
                </div>

                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="email">
                            Email
                      <input type="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2" id="email" name="email"
                          placeholder="Enter your email address" />
                    </label>
                </div>

                <div className="mb-3 w-full">
                    <label className="block font-medium mb-[2px] text-teal-700" htmlFor="message">
                            Message
                        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2" name="message" id="message"></textarea>
                    </label>
                </div>

                <button type="submit"
                        className="mb-6 inline-block w-full rounded px-6 py-2.5 font-medium uppercase leading-normal hover:shadow-md hover:bg-teal-500
                        button flex gap-1 items-center justify-center bg-primary-600 text-white font-semibold px-6 py-4 text-body-sm">
                        Send
                </button>
            </form>

            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                <div className="flex flex-wrap">
                    <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                        <div className="flex items-start">
                            <div className="shrink-0">
                                <div className="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        strokeWidth="2" stroke="currentColor" className="h-6 w-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="ml-6 grow">
                                <p className="mb-2 font-bold">
                                    Call Us
                                </p>
                                <p className="text-neutral-500 ">
                                    clink@1ofusevents.com
                                </p>
                                <p className="text-neutral-500 ">
                                (813) 419-7140
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};
