import { Eyebrow } from "../../components/Eyebrow";
import { BlogCard } from "../../components/BlogsCard";
import blogs from "../../data/blogs";


export const BlogsSection = () : JSX.Element => {

  return (
    <div id="blogs">
      <div className="container mx-auto">
        <div className="flex flex-col py-12 md:py-24 lg:py-28 gap-20">
          <div className="flex flex-col gap-6">
            <Eyebrow label="OUR BLOGS"/>
            <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
            <span className="italic">Our blogs</span> 
            </h3>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
            {blogs.map((blog, id) => (
                <BlogCard
                    key={id}
                    image={blog.image}
                    title={blog.title}
                    description={blog.description}
                    href={blog.href}
                />
            ))}
          </div>
        </div>
      </div>
    </div>
  )

}
