// import * as React from "react";
// import PropTypes from "prop-types";
// import { Helmet } from "react-helmet";
// //import { useStaticQuery, graphql } from "gatsby";

// import previewImage from "../images/og-preview.jpg";

export const SeoSection = ():JSX.Element => {
  return (
    <div></div>
  )
}

// function Seo({ lang, meta, title, description, image, url }) {
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             siteUrl
//             title
//             description
//             author
//           }
//         }
//       }
//     `
//   );

//   const siteTitle = title || site.siteMetadata.title;
//   const metaDescription = description || site.siteMetadata.description;
//   const siteUrl = site.siteMetadata.siteUrl;
//   const ogImage = image || previewImage;
//   const ogUrl = `/${url}` || "";

//   return (
//     <Helmet
//       htmlAttributes={{
//         lang,
//       }}
//       title={siteTitle}
//       meta={[
//         {
//           name: `description`,
//           content: metaDescription,
//         },
//         {
//           name: `keywords`,
//           content: `landing page, uikit, open source ui kit, landify ui kit, free uikit, figma uikit, aravinddesign, free website ui kit, free figma website ui kit, free landing page ui kit, landing page ui kit for figma, website ui kit for figma`,
//         },
//         {
//           property: `og:image`,
//           content: `${siteUrl}${ogImage}`,
//         },
//         {
//           property: `og:title`,
//           content: siteTitle,
//         },
//         {
//           property: `og:description`,
//           content: metaDescription,
//         },
//         {
//           property: `og:type`,
//           content: `website`,
//         },
//         {
//           property: `og:url`,
//           content: `${siteUrl}${ogUrl}`,
//         },
//         {
//           name: `twitter:card`,
//           content: `summary_large_image`,
//         },
//         {
//           name: `twitter:creator`,
//           content: site.siteMetadata?.author || ``,
//         },
//         {
//           name: `twitter:image`,
//           content: `${siteUrl}${ogImage}`,
//         },
//         {
//           name: `twitter:title`,
//           content: title,
//         },
//         {
//           name: `twitter:description`,
//           content: metaDescription,
//         },
//       ].concat(meta)}
//     />
//   );
// }

// Seo.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// };

// Seo.propTypes = {
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string,
//   description: PropTypes.string,
//   image: PropTypes.string,
//   url: PropTypes.string,
// };

// export default Seo;
