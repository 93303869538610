import * as React from 'react';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { CardActionArea, Link } from '@mui/material';
import { BlogButton } from "./Buttons"

type BlogCardProps = {
    title: string,
    description: string,
    image: string,
    href: string
}

export const BlogCard = ({title, description, image, href}: BlogCardProps): JSX.Element => {
  return(
    <div className="max-w-sm mx-auto bg-white rounded-xl shadow-md overflow-hidden no-underline text-inherit">
      <a href={href} className="block hover:opacity-90 transition-opacity no-underline text-inherit">
        <div>
          <img 
            className="h-full w-full object-cover" 
            src={image} 
            alt={title}
          />
          <div className="p-4">
            <h2 className="font-semibold font-display text-display-xs mb-2 text-black-800 no-underline text-inherit">
              {title}
            </h2>
            <p className="font-display text-gray-400 text-xs mb-4">
              {description}
            </p>
            <BlogButton size='lg' href={href}/>
          </div>
        </div>
      </a>
    </div>
  )
  
  // return (
  //   <Card className='mx-auto'>
  //     <Link href={href} underline='none' sx={{ color: 'inherit', textDecoration: 'none' }}>
  //       <CardActionArea>
  //         <CardMedia
  //         component="img"
  //         height="140"
  //         image={image}
  //         alt={title}
  //         />
  //         <CardContent>
  //         <Typography gutterBottom variant="h5" component="div">
  //             {title}
  //         </Typography>
  //         <Typography variant="body2" color="text.secondary" className='mb-8'>
  //             {description}
  //         </Typography>
  //         <BlogButton size='lg'/>
  //         </CardContent>
  //       </CardActionArea>
  //     </Link>
  //   </Card>
  // );
}