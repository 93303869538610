import WeddingPic from "../images/blogs-pictures/weddingpic.webp"
import NeededKnowledgePic from "../images/blogs-pictures/neededknowledge.webp"
import UnforgettablePartyPic from "../images/blogs-pictures/unforgettableparty.webp"

type blog = {
  title: string,
  description: string,
  image: string,
  href: string
};

const blogs: blog[] = [
  {
    title: "Crafting the Perfect Wedding Bar: Here Are 5 Must-Have Items",
    description: "Wedding celebrations should feature open and enjoyable libations. Here are the key requirements for a perfect bar...",
    image: WeddingPic,
    href: "/blogs/weddings"
  },
  {
    title: "Crafting an Unforgettable Party Experience: Must Have Bar Essentials",
    description: "Are you facing the challenge of organizing your upcoming corporate event? Whether it's aconference...",
    image: NeededKnowledgePic,
    href: "/blogs/neededKnowledge"
  },
  {
    title: "Bar Service for Corporate Events: What You Need to Know",
    description: "Events and parties call for delightful libations and a well-stocked bar. Here are the essential elements for a flawless...",
    image: UnforgettablePartyPic,
    href: "/blogs/unforgettableParty"
  }
];

export default blogs;
